import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login.vue'),
    meta: { layoutDisabled: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Auth/Register.vue'),
    meta: { layoutDisabled: true }
  },
  {
    path: '/',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
  },
  {
    path: '/cctv/:id',
    name: 'CCTV',
    component: () => import('../views/CCTV.vue')
  },
  {
    path: '/gencon/:id',
    name: 'Gencon',
    component: () => import('../views/Gencon.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const loggedIn = localStorage.getItem('access_token')
  if (loggedIn == null && to.path !== '/login' && to.path !== '/register') return next({ name: 'Login'})
  return next()
})

export default router
