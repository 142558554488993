import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import axios from './axios'

const Vue = createApp(App)
Vue.use(router)
Vue.use(Quasar, quasarUserOptions)
Vue.mount('#app')

axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers.common["Authorization"] = token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    router.push('/login')
    localStorage.removeItem('access_token')
  }

  if (error.response.status === 500) {
    alert("Server Error")
  }

  if (error.response.status === 403) {
    alert("Unauthorized")
    router.push('/')
  }

  return Promise.reject(error);
});