<template>
  <div class="">
    <router-view v-if="$route.meta.layoutDisabled"/>
    <q-layout view="hHh Lpr lff" class="shadow-2" v-if="!$route.meta.layoutDisabled">
      <q-header elevated>
        <q-toolbar>
          <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
          <q-toolbar-title>Field Services App</q-toolbar-title>
          <q-btn flat dense color="white" label="Account" right to="/account"/>
          <q-btn flat dense color="white" label="Logout" right @click="logout()"/>
        </q-toolbar>
      </q-header>

      <q-drawer
        v-model="drawer"
        show-if-above

        :mini="miniState"
        @mouseover="miniState = false"
        @mouseout="miniState = true"

        :width="200"
        :breakpoint="500"
        bordered
        class="bg-grey-3"
      >
        <q-scroll-area class="fit">
          <q-list padding>
            <q-item clickable v-ripple to="/" exact>
              <q-item-section avatar>
                <q-icon name="inbox" />
              </q-item-section>

              <q-item-section>
                Dashboard
              </q-item-section>
            </q-item>

            <q-item v-if="isInRole('Administrator')" clickable v-ripple to="/admin" exact>
              <q-item-section avatar>
                <q-icon name="star" />
              </q-item-section>

              <q-item-section>
                Admin
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <q-page>
          <router-view/>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import UtilMixin from './UtilMixin'

export default {
  name: 'Home',
  components: {
  },
  mixins: [
    UtilMixin
  ],
  data() {
    return {
      drawer: false,
      miniState: true,
      layout: true,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('username')
      localStorage.removeItem('roles')
      this.goto('/login')
    },
  },
}
</script>

<style lang="scss">

</style>
