import router from './router/index'

export default {
  data() {
    return {
    }
  },
  methods: {
    goto(route) {
      router.push(route)
    },
    hasCredentials() {
      const roles = localStorage.getItem('roles')
      if (roles == undefined) {
        return false
      }
      const token = localStorage.getItem('access_token')
      if (token == undefined) {
        return false
      }
      const username = localStorage.getItem('username')
      if (username == undefined) {
        return false
      }
      return true
    },
    isInRole(role) {
      const credentials = this.hasCredentials()
      if (!credentials) {
        return false
      }
      const roles = localStorage.getItem('roles')
      const index = roles.indexOf(role)
      if (index !== -1) {
        return true
      } else {
        return false
      }
    },
  },
}
  